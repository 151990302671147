import { GlobalLoadingState } from '../molecules/global-loading-state'
import { AbsoluteLayout } from './absolute-layout'

export function LoadingPage(): JSX.Element {
  return (
    <AbsoluteLayout>
      <GlobalLoadingState />
    </AbsoluteLayout>
  )
}
